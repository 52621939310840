angular.module('fingerink')
    .factory('notificacionService', ['$http', 'session', function ($http, session) {
        var sdo = {
            getNotificaciones: function (params) {
                return $http({
                    method: 'GET',
                    url: 'notifications' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            }
        };
        return sdo;
}]);
